import React, {Component} from 'react';
// reimporting jquery seems to break foundation, probably because $ was mounted to the window
// import $ from 'jquery';



class Seat extends Component {
    state = {
        showNoteIcon: false,
        showHandicappedIcon: false,
        showPoorSightLinesIcon: false,
    }

    componentDidMount() {
        switch (this.props.carId) {
            case 1:
                // Edward Gillete
                if (this.props.row >= 4 && this.props.row <= 7) {
                    this.setState({showNoteIcon: true});
                }
                break;
            case 6:
                // Hilyo, as needed for Holiday Express Layout

                // if (this.props.isHolidayTrain && [0, 1, 8, 9].includes(this.props.row))
                if ([0, 1, 8, 9].includes(this.props.row)) {
                    this.setState({showNoteIcon: true});
                }
                break;
            case 5:
                // Rochford
                if (this.props.row == 11 && this.props.col < 2) {
                    // twelvth row, AB only
                    this.setState({showPoorSightLinesIcon: true});
                }
                break;
            case 7:
                // Blue Bird, the handicapped seats and the poor sight lines
                if (this.props.row == 0 && [0, 3].includes(this.props.col)) {
                    this.setState({showHandicappedIcon: true});
                }
                if (this.props.row == 12) {
                    // thirteenth row
                    this.setState({showPoorSightLinesIcon: true});
                }
                break;
        }
    }

    render() {
        const letter = ['a','b','c','d'][this.props.col];

        const handicappedIcon = <span className="handicappedSeat"><i className="fa fa-wheelchair"></i></span>;

        const asteriskIcon = <span className="noteIcon"><i className="fa fa-asterisk"></i></span>;

        const poorSightLinesIcon = <span className="poorSightLinesIcon"><i className="fa fa-low-vision"></i></span>;

        return (
            this.props.colVal === 1 ?
            <span className={`seat row-${this.props.row + 1} col-${letter} unavailable`}>{this.state.showHandicappedIcon && handicappedIcon}{this.state.showNoteIcon && asteriskIcon}{this.state.showPoorSightLinesIcon && poorSightLinesIcon}</span>
            :
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    this.props.pickSeat(this.props.trainId, this.props.trainCarId, this.props.row, this.props.col, this.state.showHandicappedIcon, this.state.showNoteIcon, this.state.showPoorSightLinesIcon)
                }}
                className={`seat row-${this.props.row + 1} col-${letter} ${this.props.colVal === 2 ? 'selected' : ''}`}
            >{this.state.showHandicappedIcon && handicappedIcon}{this.state.showNoteIcon && asteriskIcon}{this.state.showPoorSightLinesIcon && poorSightLinesIcon}</a>
        );
    }
}

class Car extends Component {
    render() {

    /*
        // for reference:
        rows, id, name
        10  6   Hilyo
        12  1   Edward Gillette
        12  5   Rochford
        13  7   Blue Bird
        14  4   Oreville
        14  3   Harney Canyon
        14  2   Keystone
        15  10  Mystic
        15  8   Battle Creek
        17  9   Red Fern

    */
        const openSidedCarIds = [3, 9, 10]; // Harney Canyon, Red Fern, and Mystic. the rest are window-enclosed

        // instead of using carIds, I'm using char codes for supplement CSS classes to handle the 'custom' edge cases.
        const eg = this.props.car.carId == 1 ? 'eg': '';  // Edward Gillette is a special snowflake that gets its own image and css.

        const hc = this.props.car.carId == 3 ? 'hc' : ''; // Harney Canyon has a missing bench removed, unique image.

        const bc = this.props.car.carId == 8 ? 'bc': '';  // so is Battle Creek now, has a divider wall like Edward Gillette.

        const bb = this.props.car.carId == 7 ? 'bb': '';  // Blue Bird needs special arrangments to handle the photo booth and missing seat blocks.

        const hhe = this. props.car.carId == 6 ? 'hhe' : ''; // Hilyo has a different image/arrangment (originally just for the Holiday Express but they want it all the time now.)

        const rf = this.props.car.carId == 9 ? 'rf' : ''; // Red Fern has a couple end benches removed, unique image.

        const my = this.props.car.carId == 10 ? 'my' : ''; // Mystic has a couple end benches removed, unique image.

        return (
            <li className={`car rows-${this.props.car.seatmap.length}${eg}${hc}${bb}${bc}${hhe}${rf}${my}`}>
                <h2 className="car-name">{this.props.car.carName} <span className="subheader">({`${openSidedCarIds.includes(this.props.car.carId) ? 'open-sided' : 'window-enclosed'}`}) </span>
                <button className="button tiny success fill-seats" onClick={() => this.props.fillSeats(this.props.car.trainCarId)}>Fill</button>
                </h2>
                <div className={`car-desc car-${this.props.car.trainCarId}`}></div>
                <div className="seatmap">
                    {this.props.car.seatmap.map((rowSeats, row) => (
                        rowSeats.map((colVal, col) => {
                            if (this.props.car.carId == 7 && row >= 11 && col <= 1) {
                                // Blue Bird 12AB and 13AB seats do not exist.
                                return;
                            }

                            if (this.props.car.carId == 3 && ((row == 15 && col <= 1)  || (row == 0 && col >= 2))) {
                                // Harney Canyon 16AB seats or 1CD do not exist.
                                return;
                            }

                            if (this.props.car.carId == 9 && ((row == 0 && col <= 1) || (row == 16 && col >= 2))) {
                                // Red Fern 1AB and 17CD do not exist.
                                return;
                            }

                            if (this.props.car.carId == 10 && ((row == 14 && col <= 1)  || (row == 0 && col >= 2))) {
                                // Mystic 15AB seats or 1CD do not exist.
                                return;
                            }

                            return <Seat {...{colVal, col, row, carId: this.props.car.carId, trainId: this.props.trainId, isHolidayTrain: this.props.isHolidayTrain, trainCarId: this.props.car.trainCarId, pickSeat: this.props.pickSeat}}
                                  key={(row+1) * (col+1)}
                            />;
                        })
                        )
                    )}
                </div>
            </li>
        );
    }
}

class Train extends Component {
    render() {
        return (
            <div>
                <ul className={`carlist departure-station-${this.props.train.departureStationId}`}>
                {this.props.train.cars.map((car) => (
                    <Car pickSeat={this.props.pickSeat} fillSeats={this.props.fillSeats} trainId={this.props.train.trainId} isHolidayTrain={this.props.train.isHolidayTrain} car={car} key={car.trainCarId}/>
                ))}
                </ul>
            </div>
        );
    }
}

class FooterControls extends Component {
    state = {
        firstClick: false,
        showErrorTip: false,
    }

    renderExpectedPaxOrErrorMessage() {
        if (this.props.returnTrain) {
            // first check if seats are equal.
            if (this.calculateCount('outboundTrain') !== this.calculateCount('returnTrain')) {
                return `Please choose an equal number of seats on each train for ${this.props.pax} ${this.props.pax === 1 ? 'passenger' : 'passengers'}.`;
            }
        }
        if (this.props.pax !== this.calculateCount('outboundTrain')) {
            return `You requested ${this.props.pax} ${this.props.pax === 1 ? 'passenger' : 'passengers'}. Please choose ${this.props.pax} ${this.props.pax === 1 ? 'seat' : 'seats'} or go back and change the number of passengers.`;
        }
        this.setState({showErrorTip: false});
        return '';
    }

    handleNext() {
        const showErrorTip = this.renderExpectedPaxOrErrorMessage() !== '';
        this.setState({firstClick: true, showErrorTip});

        if (!showErrorTip) {
            const selectedSeats = [...this.getSelectedSeats('outboundTrain'), ...this.getSelectedSeats('returnTrain')];
            $('#requestedseats').val(JSON.stringify(selectedSeats)).trigger('change');
            $(document).foundation('reveal', 'close');
        }
    }

    closeModal() {
        $(document).foundation('reveal', 'close');
    }

    calculateCount(departingTrain) {
        /* departingTrain must be one of 'outboundTrain' | 'returnTrain' */
        if (!this.props[departingTrain] || !this.props[departingTrain].cars || !this.props[departingTrain].cars.length) {
            return 0;
        }
        const theCount = this.props[departingTrain].cars.reduce((result, car) => {
            const seats = car.seatmap.reduce((result, row) => ([...result, ...row]));
            return [...result, ...seats];
        }, [])
        .filter((seatValue) => (seatValue === 2)).length;

        return theCount;
    }

    getSelectedSeats(departingTrain) {
        const theSeats = [];

        if (this.props[departingTrain] && this.props[departingTrain].cars && this.props[departingTrain].cars.length) {
            this.props[departingTrain].cars.reduce((theSeats, car) => {
                const trainCarId = car.trainCarId;

                car.seatmap.reduce((theSeats, rowVal, row) => {
                    const rowNum = row + 1;
                    const that = this;

                    rowVal.forEach((val, col) => {
                        const letter = ['A', 'B', 'C', 'D'][col];

                        if (val === 2) {
                            // 2 is the magic code when a seat is clicked.
                            theSeats.push({trainCarId, letter, row: rowNum});
                            // TODO in another season, adult vs child rate picker, for now, ignore that in the backend
                        }
                    }, that);
                    return theSeats;
                }, theSeats);
                return theSeats;
            }, theSeats);
        }
        return theSeats;
    }

    render() {
        return (
            <div>
                {this.props.showSeatTip &&
                <div className="row collapse">
                    <div className="small-12 columns">
                        <p className="left"><span className="label round warning"><strong> <i className="fa fa-asterisk"></i> TIP: </strong></span> These benches are arranged in club seating. Club seats have limited leg room and are reserved for parties of 4 or 8.</p>
                    </div>
                </div>
                }
                {this.props.showSightLinesTip &&
                <div className="row collapse">
                    <div className="small-12 columns">
                        <p className="left"><span className="label round warning"><strong> <i className="fa fa-low-vision"></i> </strong></span> Partially obstructed sightlines.</p>
                    </div>
                </div>
                }
                {this.props.showHandicappedTip &&
                <div className="row collapse">
                    <div className="small-12 columns">
                        <p className="left"><span className="label round primary"><strong> <i className="fa fa-wheelchair"></i> </strong></span> This car is designed with mobility assistance features such as wheelchair seating and a vertical lift for those who need additional assistance. Please scroll for alternate seating if you are not in need of these features. Rows 1-2 are reserved for families with wheelchairs.</p>
                    </div>
                </div>
                }
                {!(this.state.showErrorTip || this.state.firstClick) &&
                <div className="row collapse">
                    <div className="small-12 columns">
                        <p className="right"><span className="label success">{`Please choose ${this.props.pax} ${this.props.pax === 1 ? 'passenger' : 'passengers'}${this.props.returnTrain ? ' each way' : ''}.`}</span></p>
                    </div>
                </div>
                }
                {this.state.showErrorTip && this.state.firstClick &&
                <div className="row collapse">
                    <div className="small-12 columns">
                        <p className="right"><span className={`label ${this.state.showErrorTip ? `alert` : ''}`}>{this.renderExpectedPaxOrErrorMessage()}</span></p>
                    </div>
                </div>
                }
                <div className="row collapse">
                    <div className="medium-3 columns hide-for-small-only">
                        <div className="legend"></div>
                    </div>
                    <div className="medium-9 small-12 columns">
                        <ul className="inline-list right">
                            <li>
                                <ul className="no-bullet">
                                    <li className="text-right seatcount">Outbound:</li>
                                    {this.props.returnTrain &&
                                    <li className="text-right seatcount">Return:</li>}
                                </ul>
                            </li>
                            <li>
                                <ul className="no-bullet">
                                    <li className="text-center seatcount"><span className="label round success">{this.calculateCount('outboundTrain')}</span></li>
                                    {this.props.returnTrain &&
                                    <li className="text-center seatcount"><span className="label round success">{this.calculateCount('returnTrain')}</span></li>}
                                </ul>
                            </li>
                            <li><a href="#" className="button small secondary" onClick={() => this.closeModal()}>Cancel</a></li>
                            <li><a href="#" className={`button small ${this.state.showErrorTip ? `alert disabled` : 'success'}`} onClick={(e) => {e.preventDefault(); this.handleNext()}}>Next</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

export default class SeatPickerModalContent extends Component {
    constructor(props) {
        super(props);
        this.pickSeat = this.pickSeat.bind(this);
        this.fillSeats = this.fillSeats.bind(this);
        this.resetMyState = this.resetMyState.bind(this);
        this.animateTrainCarsMidway = this.animateTrainCarsMidway.bind(this);
    }

    modalNode;
    scrollCarsTimer;

    state = {
        loading: true,
        hideOppositeSideTip: false,
        errorFetchingSeatmap: false,
        showSeatTip: false,
        showSightLinesTip: false,
        showHandicappedTip: false,
        pax: 0,
        outboundTrain: false,
        returnTrain: false,
    }

    /*
    mockResponse = {
        outboundTrain: {
            trainId: 12345,
            departureStationId: 1,
            desc: 'Hill City 10:00am',
            cars: [
                {
                    trainCarId: 12,
                    carName: 'Edward Gillette',
                    seatmap: [
                        [1, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                    ]
                },
                {
                    trainCarId: 10,
                    carName: 'Hilyo',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                    ]
                },
                {
                    trainCarId: 14,
                    carName: 'Keystone',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 1, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                    ]
                },
                {
                    trainCarId: 13,
                    carName: 'Blue Bird',
                    seatmap: [
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 1, 0, 0],
                        [0, 0, 1, 1],
                        [0, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 0, 0],
                        [1, 1, 0, 1],
                        [1, 1, 1, 1],
                    ]
                },
                {
                    trainCarId: 16,
                    carName: 'Red Fern',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                    ]
                }

            ]
        },
        returnTrain: {
            trainId: 12346,
            departureStationId: 2,
            desc: 'Keystone 11:45am',
            cars: [
                {
                    trainCarId: 112,
                    carName: 'Edward Gillette',
                    seatmap: [
                        [1, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                    ]
                },
                {
                    trainCarId: 110,
                    carName: 'Hilyo',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 1, 1],
                    ]
                },
                {
                    trainCarId: 114,
                    carName: 'Keystone',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 1, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                    ]
                },
                {
                    trainCarId: 113,
                    carName: 'Blue Bird',
                    seatmap: [
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 1, 0, 0],
                        [0, 0, 1, 1],
                        [0, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 0, 0],
                        [1, 1, 0, 1],
                        [1, 1, 1, 1],
                    ]
                },
                {
                    trainCarId: 16,
                    carName: 'Red Fern',
                    seatmap: [
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 0, 0],
                        [1, 0, 0, 0],
                        [1, 0, 1, 1],
                        [1, 1, 1, 1],
                        [1, 1, 1, 1],
                        [0, 0, 0, 0],
                        [1, 1, 1, 1],
                    ]
                }

            ]
        },
    }
    */

    componentDidMount() {
        // respond to actual DOM foundation reveal events
        // $(document).foundation();

        $(this.modalNode).off().on('closed.fndtn.reveal', (e) => this.resetMyState(e));
        $(this.modalNode).off().on('open.fndtn.reveal', (e) => this.fetchSeatMap(e));
        $(this.modalNode).on('open.fndtn.reveal', (e) => {
            this.scrollCarsTimer = setTimeout(() => this.animateTrainCarsMidway(e), 700);
        });

        // console.log("component mounted, events handlers bound.");
    }

    componentWillUnmount() {
        $(this.modalNode).off('open.fndtn.reveal');
        $(this.modalNode).off('closed.fndtn.reveal');
    }

    resetMyState(e) {
        console.log('reset my state invoked'); // at this time, this function is never invoked.
        // see https://github.com/zurb/foundation-sites/issues/5482
        if (e.namespace !== 'fndtn.reveal') {
           return;
        }
        this.setState({
            loading: true,
            hideOppositeSideTip: false,
            errorFetchingSeatmap: false,
            pax: 0,
            showHandicappedTip: false,
            showSeatTip: false,
            showSightLinesTip: false,
            outboundTrain: false,
            returnTrain: false,
        });
    }

    animateTrainCarsMidway(e) {
        if (e.namespace !== 'fndtn.reveal') {
           return;
        }
        // console.log('animated scroll via JQ');

        let halfway = $("div.train-carlists").prop('scrollWidth') ? $("div.train-carlists").prop('scrollWidth') / 2 : 800;
        let viewableWidth = $("div.train-carlists").width();
        let scrollDistance = halfway - viewableWidth / 2;
        $('div.train-carlists').animate({ scrollLeft: scrollDistance }, 700);
    }

    fetchSeatMap(e) {
        // invoked by reveal open event. Use this as an opportunity to refresh available seat maps and to get the pax.
        // see https://github.com/zurb/foundation-sites/issues/5482
        if (e.namespace !== 'fndtn.reveal') {
           return;
        }
        // console.log('fetchSeatMap invoked');
        let pax = 0,
            shouldPayLapsAndTakeSeats = 0,
            reservation_id = 0;

        const that = this;
        shouldPayLapsAndTakeSeats = parseInt($('#shouldPayLapsAndTakeSeats').val()) || 0;
        pax += parseInt($('#adults').val()) || 0;
        pax += parseInt($('#children').val()) || 0;
        pax += parseInt($('#specials').val()) || 0;
        pax += parseInt($('#escorts').val()) || 0;

        if (shouldPayLapsAndTakeSeats) {
            pax += parseInt($('#laps').val()) || 0;
            console.log("Infants age 0-1 will require a seat for this train.");
        }

        this.setState({pax, loading: true, showHandicappedTip:false, showSeatTip: false, showSightLinesTip: false});

        let outbound_train_id = false,
            return_train_id = false;

        outbound_train_id = $('input[type=radio][name=outbound_train_id]:checked').val() ||
                        $('#outbound_train_id').val() ||
                        $('#trainid').val();

        return_train_id = $('input[type=radio][name=return_train_id]:checked').val() ||
                        $('#return_train_id').val() ||
                        $('#returntrainid').val();

        const params = {...{outbound_train_id: outbound_train_id}, ...{return_train_id: return_train_id}};
        if (!outbound_train_id || outbound_train_id === 0 || outbound_train_id == '0') {
            // no train selected, skip the ajax call and show an error.
            this.setState({loading:false, errorFetchingSeatmap: true});
            return;
        }

        reservation_id = parseInt($('#reservation_id').val()) || 0;

        if (reservation_id) {
            params.reservation_id = reservation_id;
        }

        $.ajax({
            url: this.props.url,
            method: "GET",
            data: params,
            dataType: 'json',
        }).done((result) => {
            that.setState({loading: false, errorFetchingSeatmap:false, ...result});
        }).fail((xhr) => {
            // console.log("error", xhr);
            that.setState({loading:false, errorFetchingSeatmap: true})
            // that.setState(Object.assign({loading: false, errorFetchingSeatmap: false}, that.mockResponse));
        });
    }

    pickSeat(trainId, trainCarId, rowIdx, colIdx, isHandicappedSeat, isClubSeat, isPoorSightLinesSeat) {
        let modifiedTrain, key;

        if (this.state.outboundTrain.trainId === trainId) {
            modifiedTrain = this.state.outboundTrain;
            key = 'outboundTrain';
        }
        if (this.state.returnTrain.trainId === trainId) {
            modifiedTrain = this.state.returnTrain;
            key = 'returnTrain';
        }
        const carIdx = modifiedTrain.cars.map((x) => (x.trainCarId)).indexOf(trainCarId);

        // const isEdwardGilette = modifiedTrain.cars[carIdx].carName == "Edward Gillette";

        const isBlueBird = modifiedTrain.cars[carIdx].carId == 7;

        /*  show the tip if it's not already shown (sticky on first click) and only show for
            this car, these rows, if the clicked seat is about to be selected (seatmap value zero)
        */
        const showSeatTip = this.state.showSeatTip || isClubSeat;
        const showSightLinesTip = this.state.showSightLinesTip || isPoorSightLinesSeat;
        const showHandicappedTip = this.state.showHandicappedTip || isHandicappedSeat || isBlueBird;

        modifiedTrain.cars[carIdx].seatmap[rowIdx][colIdx] = modifiedTrain.cars[carIdx].seatmap[rowIdx][colIdx] === 0 ? 2 : 0;
        clearTimeout(this.scrollCarsTimer); // just in case someone clicks a seat before the animation is triggered.
        this.setState({[key]: modifiedTrain, showSeatTip, showSightLinesTip, showHandicappedTip});
    }

    fillSeats(trainCarId) {
        // console.log("FILL SEATS for " + trainCarId);
        let modifiedTrain, key;

        if (this.state.outboundTrain.cars.map((x) => (x.trainCarId)).includes(trainCarId)) {
            modifiedTrain = this.state.outboundTrain;
            key = 'outboundTrain';
        }
        if (this.state.returnTrain && this.state.returnTrain.cars.map((x) => (x.trainCarId)).includes(trainCarId)) {
            modifiedTrain = this.state.returnTrain;
            key = 'returnTrain';
        }

        const carIdx = modifiedTrain.cars.map((x) => (x.trainCarId)).indexOf(trainCarId);

        modifiedTrain.cars[carIdx].seatmap.forEach((row, rowIdx) => (
            row.forEach((seatVal, colIdx) => {
                if (modifiedTrain.cars[carIdx].carId == 7 && rowIdx >= 11 && colIdx <= 1) {
                    // Blue Bird 12AB and 13AB seats do not exist.
                    return;
                }
                modifiedTrain.cars[carIdx].seatmap[rowIdx][colIdx] = seatVal === 0 ? 2 : seatVal;
            }))
        );
        this.setState({[key]: modifiedTrain});
    }

    renderTrainDescription(train, outboundOrReturn) {
        // outboundOrReturn must be "out" or "return" for css class matching.
        let keystoneArrow = '',
            hillcityArrow= '';
        if (train.departureStationId == 1) {
            hillcityArrow = <span className="fa fa-arrow-circle-left"></span>;
            keystoneArrow = '';
        } else {
            keystoneArrow = <span className="fa fa-arrow-circle-right"></span>;
            hillcityArrow = '';
        }
        return (
            <h4 className={`train-description-${outboundOrReturn}`}><small>Departs</small> {hillcityArrow} <strong>{train.desc}</strong> {keystoneArrow}</h4>
        );
    }

    render() {

        return (
            <div data-reveal
                id="seat-picker-modal"
                className="reveal-modal xlarge"
                ref={ref => ref && (this.modalNode = ref)}>
                {
                this.state.loading
                ?
                    (<h1><i className="fa fa-spinner fa-spin fa-fw"></i> Loading available seats...</h1>)
                : this.state.errorFetchingSeatmap
                    ?
                    (
                        <div className="row collapse">
                            <div className="small-12 columns">
                                <h1 className="modal-title">Ooops</h1>
                                <p className="label alert">There was a problem loading seat maps. Please check the trains and try again.</p>
                            </div>
                        </div>
                    )
                    :
                    (
                         <div className="row collapse">
                            <div className="small-12 columns">
                                <h1 className="modal-title">Choose Seats</h1>
                                <p className="hide-for-small-only">Scroll through the train cars and click on your desired seats. Please avoid leaving single-seat gaps. Try to leave room for other passengers to sit together.</p>
                                <p className="show-for-small-only">Swipe left through the train cars and tap on your desired seats. Please avoid leaving single-seat gaps. Try to leave room for other passengers to sit together.</p>
                                {!this.state.hideOppositeSideTip && <p><span className="label round warning"><strong> TIP </strong></span> Choose seats on the opposite side for the return trip, so you can enjoy scenery from both sides of the track!</p>}
                                <div className="train-carlist-container">
                                    {this.state.outboundTrain && this.renderTrainDescription(this.state.outboundTrain, "out")}
                                    {this.state.returnTrain && this.renderTrainDescription(this.state.returnTrain, "return")}
                                    <div className="train-carlists">
                                        {this.state.outboundTrain &&
                                         <Train train={this.state.outboundTrain} pickSeat={this.pickSeat} fillSeats={this.fillSeats}/>}
                                        {this.state.returnTrain &&
                                        <Train train={this.state.returnTrain} pickSeat={this.pickSeat} fillSeats={this.fillSeats}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {!this.state.loading && this.state.outboundTrain &&
                <FooterControls {...this.state}/>}
                <a className="close-reveal-modal">&times;</a>
            </div>
        );
    }
}
